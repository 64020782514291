const maxRules = 1e10

function createGenerateId(options) {
    let ruleCounter = 0

    return (rule) => {
        ruleCounter += 1
        
        if (ruleCounter > maxRules) {
            throw new Error(`[JSS] You might have a memory leak. Rule counter is at ${ruleCounter}.`)
        }
        
        if(options.minify){
            return `c-${ruleCounter}`
        }

        return `${rule.key}-${ruleCounter}`
    }
}

module.exports = (jss) => {
    // custom Jss config
    jss.setup({
        createGenerateId,
    })
  
    // custom JssProvider props 
    return {
        jss,
        generateId: jss.generateId,
    }
}