// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archive-post-js": () => import("./../../../src/templates/archive-post.js" /* webpackChunkName: "component---src-templates-archive-post-js" */),
  "component---src-templates-single-career-js": () => import("./../../../src/templates/single-career.js" /* webpackChunkName: "component---src-templates-single-career-js" */),
  "component---src-templates-single-game-js": () => import("./../../../src/templates/single-game.js" /* webpackChunkName: "component---src-templates-single-game-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single-page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

